import React from "react";
import icon from "../assets/icon.png";
import playstore from "../assets/playstore.png";
import appstore from "../assets/appstore.png";
import { Button } from "./ui/Button";
import { Fade } from "react-awesome-reveal";

function Header({ showTabs = true }) {
  const handleclick = (e) => {
    var id = e.target.name;
    var elmntToView = document.getElementById(id);
    elmntToView.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  return (
    <Fade triggerOnce cascade>
      <div className="h-full font-poppins bg-cover lg:bg-contain md:bg-right bg-no-repeat bg-hero-img w-full flex justify-start items-start">
        <div className="flex-1 pt-8 pb-16 px-12 flex flex-col justify-center items-start gap-8 md:gap-14">
          <img src={icon} alt="logo" className="h-20" />

          <h1 className="text-primary font-semibold text-5xl md:text-6xl lg:text-8xl">
            E-Closet
          </h1>

          <div className="text-text font-semibold text-xl tracking-wide flex flex-col gap-2">
            <p className="">List and Rent the Perfect Outfit</p>
            <p className="">Rent, Buy, Earn</p>
          </div>

          <div>
            <h1 className="font-bold text-xl mt-8">Download Now</h1>
            <p>Our App is available on Play store and App store.</p>
            <div className="flex gap-4 mt-6">
              <a
                target="_blank"
                href="https://apps.apple.com/in/app/e-closet/id6621264661"
              >
                <img
                  className="h-10 hover:cursor-pointer"
                  src={appstore}
                  alt="appstore"
                />
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.napworks.ecloset"
              >
                <img
                  className="h-10 hover:cursor-pointer"
                  src={playstore}
                  alt="playstore"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="flex-1 h-full w-full pt-8 hidden md:block">
          {showTabs ? (
            <div className="w-full flex justify-center items-center gap-8">
              <Button onClick={handleclick} text="About Us" name="about" />
              <Button onClick={handleclick} text="Contact Us" name="contact" />
            </div>
          ) : null}
        </div>
      </div>
    </Fade>
  );
}

export default Header;
