import React from 'react'
import Header from '../components/Header'

function Home() {
    return (
        <div className='h-full w-full'>
            <Header showTabs={false} />
        </div>
    )
}

export default Home